var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _c(
            "gov-table",
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "gov-table-row",
                    [
                      _vm._l(_vm.columns, function(column, index) {
                        return _c(
                          "gov-table-header",
                          {
                            key: "ResourceListingTableHeading-" + index,
                            attrs: { clickable: column.hasOwnProperty("sort") },
                            on: {
                              click: function($event) {
                                return _vm.onSort(column)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(column.heading) +
                                "\n          "
                            ),
                            column.hasOwnProperty("sort")
                              ? [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.sortText(column)) +
                                      "\n          "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      _c("gov-table-header")
                    ],
                    2
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "body" },
                [
                  _vm._l(_vm.resources, function(resource) {
                    return _c(
                      "gov-table-row",
                      { key: resource.id },
                      [
                        _vm._l(_vm.columns, function(column, index) {
                          return _c("gov-table-cell", {
                            key: "ResourceListingTableCell-" + index,
                            domProps: {
                              innerHTML: _vm._s(column.render(resource))
                            }
                          })
                        }),
                        _c(
                          "gov-table-cell",
                          { attrs: { right: "" } },
                          [
                            _c(
                              "gov-link",
                              {
                                attrs: {
                                  to: _vm.viewRoute
                                    ? _vm.viewRoute(resource)
                                    : null
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onAction(resource)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.actionText) +
                                    "\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      2
                    )
                  }),
                  _vm.resources.length === 0
                    ? _c(
                        "gov-table-row",
                        [
                          _c(
                            "gov-table-cell",
                            { attrs: { colspan: _vm.columns.length + 1 } },
                            [_vm._v("None found")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            2
          ),
          _c("ck-pagination", {
            attrs: {
              "current-page": _vm.currentPage,
              "total-pages": _vm.totalPages
            },
            on: { previous: _vm.onPrevious, next: _vm.onNext }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }