var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.to
    ? _c(
        "router-link",
        {
          staticClass: "govuk-link govuk-link--no-visited-state",
          attrs: { to: _vm.to }
        },
        [_vm._t("default")],
        2
      )
    : _c(
        "a",
        {
          staticClass: "govuk-link govuk-link--no-visited-state",
          attrs: { href: _vm.href || "javascript:;" },
          on: { click: _vm.onClick }
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }