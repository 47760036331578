var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-body",
    [
      _vm._v(
        "\n  Showing page " +
          _vm._s(_vm.currentPage) +
          " of " +
          _vm._s(_vm.totalPages) +
          "\n  "
      ),
      _vm.currentPage > 1
        ? _c("gov-link", { on: { click: _vm.onPrevious } }, [
            _vm._v("Previous")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentPage < _vm.totalPages
        ? _c("gov-link", { on: { click: _vm.onNext } }, [_vm._v("Next")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }